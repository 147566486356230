import { SelectionChangedEvent } from "ag-grid-community";
import { EmailTemplateEntity } from "domain/entity/EmailTemplate/EmailTemplateEntity";
import { INITIAL_EMAIL_TEMPLATE_COL_DEF } from "presentation/constant/EmailTemplate/EmailTemplateMaintenanceColumnDefinition";
import { EmailTemplateMaintenanceConstant } from "presentation/constant/EmailTemplate/EmailTemplateMaintenanceConstant";
import { useEmailTemplateMaintenanceVM } from "presentation/hook/EmailTemplate/useEmailTemplateMaintenanceVM";
import { useEmailTemplateMaintenanceTracked } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const EmailTemplateTablePanel: React.FC = () => {
    const [emailTemplateState] = useEmailTemplateMaintenanceTracked();
    const emailTemplateVM = useEmailTemplateMaintenanceVM();
    const gridRef: any = useRef(null);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        emailTemplateVM.updateSelectedRows(selectedRows);
    }, [emailTemplateVM])

    const handleAddClick = useCallback(() => {
        emailTemplateVM.onAdd();
    }, [emailTemplateVM])

    const handleRowDoubleClick = useCallback((entity: EmailTemplateEntity) => {
        emailTemplateVM.onRowDoubleClick(entity);
    }, [emailTemplateVM])

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='email-template-table'
                headerLabel={EmailTemplateMaintenanceConstant.TITLE}
                isNewColumnSetting={true}
                columns={INITIAL_EMAIL_TEMPLATE_COL_DEF}
                data={emailTemplateState.tableData ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={!emailTemplateState.masterState.isSliderOpen}
                onAddClick={handleAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any, entity: EmailTemplateEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
            />
        );
    }, [emailTemplateState.tableData, emailTemplateState.masterState.isSliderOpen, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(EmailTemplateTablePanel);
