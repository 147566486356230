
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EmailTemplateEntity, EMPTY_EMAIL_TEMPLATE_ENTITY } from "domain/entity/EmailTemplate/EmailTemplateEntity";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { EmailTemplateRepository } from "domain/repository/EmailTemplate/EmailTemplateRepo";
import { PredefineVariableRepository } from "domain/repository/EmailTemplate/PredefineVariableRepo";
import _ from "lodash";
import { createEmailTemplateMaintenanceValidationSchema } from "presentation/constant/EmailTemplate/EmailTemplateMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EmailTemplateMaintenanceModel } from "presentation/model/EmailTemplate/EmailTemplateMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface EmailTemplateMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<EmailTemplateMaintenanceModel>> | ((value: SetStateAction<EmailTemplateMaintenanceModel>) => void)
    ],
    repo: EmailTemplateRepository,
    customerRepo: CustomerRepository,
    predefineVariableRepo: PredefineVariableRepository
}

export const EmailTemplateMaintenanceVM = ({ dispatch, repo, customerRepo, predefineVariableRepo }: EmailTemplateMaintenanceVMProps) => {
    const [emailTemplateMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await customerRepo.getCustomerActive().then(
            customers => {
                const sortCustomers = _.orderBy(customers, ["customerCode"]);
                let customerCodeDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        customerCodeDropdownOptions.push({
                            dropdownLabel: sortCustomers[i].customerCode,
                            tagLabel: sortCustomers[i].id.toString(),
                            value: sortCustomers[i].customerCode
                        });
                    }
                }

                emailTemplateMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        )

        await predefineVariableRepo.getAllPredefineVariables().then(
            predefineVariables => {
                const sortCustomers = _.orderBy(predefineVariables, ["name"]);
                let variableDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        variableDropdownOptions.push({
                            dropdownLabel: sortCustomers[i].name ?? "",
                            tagLabel: sortCustomers[i].id?.toString() ?? "",
                            value: sortCustomers[i].name ?? "",
                        });
                    }
                }

                emailTemplateMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        variableDropdownOptions: variableDropdownOptions,
                    }
                }))
            }
        )
    }

    const onSearch = async () => {
        const entities = await repo.getEntities();

        emailTemplateMainDispatch(prevState => ({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_EMAIL_TEMPLATE_ENTITY,
            isBackMaster: false,
        }))
    }

    const updateSelectedRows = async (selecedRows: EmailTemplateEntity[]) => {
        emailTemplateMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
            }
        })
    }

    const onAdd = () => {
        emailTemplateMainDispatch(prevState => {
            const defaultVariable = prevState.dynamicOptions.variableDropdownOptions[0].value ?? null;
            let tempEmailTemplateEty = { ...EMPTY_EMAIL_TEMPLATE_ENTITY };
            tempEmailTemplateEty.variable = defaultVariable;

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: tempEmailTemplateEty,
                    isSliderOpen: true,
                    isTabularDataActive: false,
                },
                currentSelectedRow: tempEmailTemplateEty
            }
        })
    }

    const onEdit = (currentEntity: EmailTemplateEntity) => {
        emailTemplateMainDispatch(prevState => {
            const defaultVariable = prevState.dynamicOptions.variableDropdownOptions[0].value ?? null;
            currentEntity.variable = defaultVariable;

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    }
                }
            }
        })
    }

    const onRowDoubleClick = (entity: EmailTemplateEntity) => {
        emailTemplateMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: entity,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
            }
        })
    }

    const onReset = () => {
        emailTemplateMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_EMAIL_TEMPLATE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        emailTemplateMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: { ...EMPTY_EMAIL_TEMPLATE_ENTITY },
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    editingEntity: EMPTY_EMAIL_TEMPLATE_ENTITY
                },
                isBackMaster: true,
            }
        })
    }

    const onCancel = () => {
        emailTemplateMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    currentSelectItem: EMPTY_EMAIL_TEMPLATE_ENTITY,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }

    const onSaveClicked = () => {
        emailTemplateMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: EmailTemplateEntity, isAdd: boolean) => {
        const valResult = await Validation(createEmailTemplateMaintenanceValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            emailTemplateMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }

        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        emailTemplateMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === 'emailTemplate') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        emailTemplateMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }
    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        emailTemplateMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
            }
        }))
    }


    return {
        onDateRangeChange: onDateRangeChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onCancel: onCancel,
        onRowDoubleClick: onRowDoubleClick,
    }
}